import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import LoadingIndicator from '../components/LoadingIndicator'
import { toastError, toastInfo, toastSuccess } from '../utils/toast'
import { SisAPI } from '../apis/SisAPI'
import { SisArchitectureCourse, SisCohort, SisProgram } from '../apis/entities/sis.entity'
import sortSvg from '../images/sort.svg'
import sort2Svg from '../images/sort2.svg'
import pencilSvg from '../images/pencil.svg'
import mapCourseSvg from '../images/map-course.svg'
import editCourseSvg from '../images/edit-course.svg'
import {
  alternateStyleV2,
  Direction,
  DirectionAsc,
  DirectionDesc,
  tooltipStyle,
  tooltipStyleReverse
} from '../utils/TableUtils'
import { Tooltip } from 'react-tooltip'
import DropDownMenu, { SHOW_ALL_ID, DropDownItem } from '../components/DropDownMenu'
import Emitter, { Events } from '../core/emitter'

const showAllItems = { id: SHOW_ALL_ID, name: 'Show all', value: undefined }

const defaultProgram: DropDownItem = {
  id: '0',
  name: 'Program',
  value: undefined,
  isLabel: true
}

const unmappedOption: DropDownItem = {
  id: 'unmapped',
  name: 'Unmapped',
  value: 'unmapped',
  isLabel: false
}

const defaultCohort: DropDownItem = {
  id: '0',
  name: 'Cohort',
  value: undefined,
  isLabel: true
}

const enum TableHeader {
  // must match with sortKey
  Program = 'programName',
  Cohort = 'cohortName',
  Course = 'displayName',
  CourseId = 'externalId',
  Action = 'action'
}

export default function ClientManageCoursesView() {
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const lastPath = location.pathname.split('/').pop()
  const clientId = lastPath || ''
  const [rawPrograms, setRawPrograms] = useState<SisProgram[]>([])

  // dropdown items
  const [programs, setPrograms] = useState<DropDownItem[]>([defaultProgram, showAllItems])
  const [cohorts, setCohorts] = useState<DropDownItem[]>([defaultCohort, showAllItems])
  const [selectedPrograms, setSelectedPrograms] = useState<string[] | undefined>(undefined)
  const [selectedCohorts, setSelectedCohorts] = useState<string[] | undefined>(undefined)
  const refDropDownProgram = useRef<any>(null)
  const refDropDownCohort = useRef<any>(null)

  const refTableDiv = useRef<HTMLDivElement | null>(null)

  const tableHeaders = useMemo(
    () => [
      { key: TableHeader.Program, value: 'Program', sorting: true, sortKey: 'programName' },
      { key: TableHeader.Cohort, value: 'Cohort', sorting: true, sortKey: 'cohortName' },
      { key: TableHeader.Course, value: 'Course(s)', sorting: true, sortKey: 'displayName' },
      { key: TableHeader.CourseId, value: 'Course ID', sorting: true, sortKey: 'externalId' },
      { key: TableHeader.Action, value: '', sorting: false, sortKey: '' }
    ],
    []
  )

  const [mappedCourses, setMappedCourses] = useState<SisArchitectureCourse[]>([])

  const [sortConfig, setSortConfig] = useState<{
    key: string
    direction: Direction
  } | null>(null)
  const [order, setOrder] = useState<{ [key: string]: Direction }>({})

  const filteredCourses = useMemo(() => {
    // console.log('filteredCourses', selectedPrograms, selectedCohorts)
    return mappedCourses.filter(course => {
      if (selectedPrograms && selectedPrograms.length > 0) {
        if (selectedPrograms[0] === unmappedOption.value) {
          // return no mapping in course
          return !course.programId
        } else if (!selectedPrograms.includes(course.programId)) {
          return false
        }
      }
      if (selectedCohorts && selectedCohorts.length > 0) {
        // match the name of cohort
        const cohort = course.cohortName
        if (!selectedCohorts.includes(cohort)) {
          return false
        }
      }
      return true
    })
  }, [mappedCourses, selectedPrograms, selectedCohorts])

  const sortedCourses = useMemo(() => {
    if (sortConfig !== null) {
      return filteredCourses.sort((a: any, b: any) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === DirectionAsc ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === DirectionAsc ? 1 : -1
        }
        return 0
      })
    }
    return filteredCourses
  }, [filteredCourses, sortConfig])

  // object map for editing course name
  const [editCourseNameMap, setEditCourseNameMap] = useState<{ [key: string]: boolean }>({})

  const onClickSort = (key: string) => {
    // console.log('onClickSort', key)
    let ascending = true
    if (sortConfig && sortConfig.key === key && sortConfig.direction === DirectionAsc) {
      ascending = false
    }
    setOrder({ [key]: ascending ? DirectionAsc : DirectionDesc })
    setSortConfig({ key, direction: ascending ? DirectionAsc : DirectionDesc })
  }

  const getTooptipStyle = (key: string): any => {
    tableHeaders.forEach(header => {
      if (header.key === key) {
        return order[key] === DirectionAsc ? tooltipStyleReverse : tooltipStyle
      }
    })
  }

  const getSortingText = (key: string): string => {
    let text = ''
    tableHeaders.forEach(header => {
      if (header.key === key) {
        text = order[key] === DirectionAsc ? 'Sort Z to A' : 'Sort A to Z'
      }
    })
    return text
  }

  const updateCourse = useCallback(
    async (courseId: string, displayName: string) => {
      try {
        await SisAPI.updateCourse(courseId, {
          displayName
        })
        toastSuccess('Course name updated successfully')
        // update mapped courses
        const newMappedCourses = mappedCourses.map(course => {
          if (course.id === courseId) {
            course.displayName = displayName
          }
          return course
        })
        setMappedCourses(newMappedCourses)
      } catch (error) {
        console.log('error', error)
        toastError(`${error}`)
      }
    },
    [mappedCourses]
  )

  const onSelectedProgram = (item: DropDownItem) => {
    if (item.value !== undefined && item.value !== null) {
      setSelectedPrograms([item.value])
    } else if (item.id === SHOW_ALL_ID) {
      setSelectedPrograms(undefined)
    }

    // reset cohort
    setSelectedCohorts(undefined)
    refDropDownCohort.current?.reset()
  }

  const onSelectedCohort = (item: DropDownItem) => {
    if (item.value !== undefined && item.value !== null) {
      setSelectedCohorts([item.value])
    } else if (item.id === SHOW_ALL_ID) {
      setSelectedCohorts(undefined)
    }
  }

  const fetchPrograms = useCallback(async () => {
    const data = await SisAPI.getPrograms(clientId)
    // sort by alphabet name
    data.sort((a, b) => a.name.localeCompare(b.name))
    setRawPrograms(data)
    // convert data to dropdown items
    const programOptions = data.map(program => {
      return { id: program.id, name: program.name, value: program.id, isLabel: false }
    })

    setPrograms([defaultProgram, ...programOptions, unmappedOption, showAllItems])

    // flatten all cohorts
    const allCohorts = data.reduce((acc, program) => {
      return [...acc, ...program.cohorts]
    }, [] as SisCohort[])

    // group cohorts by unique name
    const uniqueCohorts = allCohorts.reduce((acc, cohort) => {
      if (!acc[cohort.name]) {
        acc[cohort.name] = cohort
      }
      return acc
    }, {} as { [key: string]: SisCohort })

    // sort cohorts by numerical/chronological order
    const sortedCohorts = Object.values(uniqueCohorts).sort((a, b) => {
      const aName = a.name.replace(/[^0-9]/g, '')
      const bName = b.name.replace(/[^0-9]/g, '')
      return parseInt(aName) - parseInt(bName)
    })

    // convert data to dropdown items
    const cohortOptions = sortedCohorts.map(cohort => {
      return { id: cohort.id, name: cohort.name, value: cohort.name, isLabel: false }
    })

    setCohorts([defaultCohort, ...cohortOptions, showAllItems])
  }, [clientId])

  const fetchArchitectures = useCallback(
    async (sliently: boolean = false) => {
      try {
        if (!sliently) {
          setIsLoading(true)
        }
        const res = await SisAPI.getArchitectures(clientId)
        const mappedCourses = res.courses
        const allCourses = await SisAPI.getCourses(clientId)
        const nonMappedCourses = allCourses
          .filter(course => {
            return !mappedCourses.find(mappedCourse => mappedCourse.id === course.id)
          })
          .map(course => {
            return {
              id: course.id,
              programName: '',
              cohortName: '',
              displayName: course.displayName,
              externalId: course.externalId,
              name: course.name
            } as SisArchitectureCourse
          })
        // console.log('nonMappedCourses', nonMappedCourses)
        const courses = [...mappedCourses, ...nonMappedCourses]
        setMappedCourses(courses)
        // console.log('fetchArchitectures', res)
      } catch (error) {
        console.log('error', error)
        toastError(`${error}`)
      } finally {
        if (!sliently) {
          setIsLoading(false)
        }
      }
    },
    [clientId]
  )

  const getDisplayValue = (key: string, item: SisArchitectureCourse | any): string => {
    switch (key) {
      case TableHeader.Program:
        return item.programName
      case TableHeader.Cohort:
        return item.cohortName
      case TableHeader.Course:
        return item.displayName
      case TableHeader.CourseId:
        return `ID:${item.externalId} - ${item.name}`
      default:
        return item[key]
    }
  }

  const onEditCourseName = useCallback(
    (item: SisArchitectureCourse) => {
      // toggle edit course name
      const isEditing = editCourseNameMap[item.id]
      setEditCourseNameMap({ ...editCourseNameMap, [item.id]: !isEditing })
    },
    [editCourseNameMap]
  )

  const getColumnWidth = (header: TableHeader): string => {
    switch (header) {
      case TableHeader.Program:
        return 'w-[28%]'
      case TableHeader.Cohort:
        return 'w-[20%]'
      case TableHeader.Course:
        return 'w-[20%]'
      case TableHeader.CourseId:
        return 'w-[30%]'
      case TableHeader.Action:
        return 'min-w-[60px]'
      default:
        return 'min-w-[200px]'
    }
  }

  const onEditCourseInformation = useCallback((item: SisArchitectureCourse) => {
    const tmpCourse = {
      ...item,
      // convert to date object for input field
      startDate: item.startDate ? new Date(item.startDate) : null,
      endDate: item.endDate ? new Date(item.endDate) : null,
      censusDate: item.censusDate ? new Date(item.censusDate) : null
    }
    Emitter.emit(Events.OnShowEditCourse, { course: tmpCourse })
  }, [])

  const onEditCourseMapping = useCallback(
    (item: SisArchitectureCourse) => {
      // check if there is any program for this course
      if (rawPrograms.length === 0) {
        toastInfo('No program found in the client')
        return
      }

      Emitter.emit(Events.OnShowEditCourseMapping, {
        course: item,
        programs: rawPrograms
      })
    },
    [rawPrograms]
  )

  const renderRow = useCallback(
    (item: SisArchitectureCourse) => {
      // console.log('item', item)
      return (
        <tr key={item.id}>
          {tableHeaders.map((header, index: number) => {
            return (
              <td
                key={header.key}
                className={`${alternateStyleV2('td', index)} h-[55px] ${getColumnWidth(header.key)}`}>
                <div className="flex flex-row items-center justify-center w-full px-[12px]">
                  {header.key === TableHeader.Course && editCourseNameMap[item.id] ? (
                    <input
                      type="text"
                      className="w-full text-center outline-none text-[#92929D] font-[600] text-[14px] text-roboto underline"
                      defaultValue={item.displayName}
                      autoFocus
                      onKeyUp={async e => {
                        if (e.key === 'Enter') {
                          // check if value is different
                          if (item.displayName !== e.currentTarget.value) {
                            await updateCourse(item.id, e.currentTarget.value)
                            setEditCourseNameMap({ ...editCourseNameMap, [item.id]: false })
                          }
                        } else if (e.key === 'Escape') {
                          // cancel
                          setEditCourseNameMap({ ...editCourseNameMap, [item.id]: false })
                        }
                      }}
                    />
                  ) : (
                    header.key !== TableHeader.Action && (
                      <div className="font-[600] text-[14px] text-roboto w-full">
                        {getDisplayValue(header.key, item)}
                      </div>
                    )
                  )}
                  {header.key === TableHeader.Course && (
                    <>
                      <div className="grow" />
                      <img
                        className="cursor-pointer"
                        src={pencilSvg}
                        alt="edit-course-name"
                        onClick={() => onEditCourseName(item)}
                        data-tooltip-id="tooltip-edit-course-name"
                      />
                    </>
                  )}
                  {header.key === TableHeader.CourseId && (
                    <>
                      <div className="grow" />
                      <img
                        className="cursor-pointer"
                        src={mapCourseSvg}
                        alt="map-course"
                        onClick={() => onEditCourseMapping(item)}
                        data-tooltip-id="tooltip-map-course"
                      />
                    </>
                  )}
                  {header.key === TableHeader.Action && (
                    <img
                      className="cursor-pointer w-[24px]"
                      src={editCourseSvg}
                      alt="edit-course"
                      onClick={() => onEditCourseInformation(item)}
                      data-tooltip-id="tooltip-edit-course"
                    />
                  )}
                </div>
              </td>
            )
          })}
        </tr>
      )
    },
    [editCourseNameMap, onEditCourseInformation, onEditCourseMapping, onEditCourseName, tableHeaders, updateCourse]
  )

  const onClosedEditCourse = useCallback(() => {
    fetchArchitectures(true)
  }, [fetchArchitectures])

  const onClosedEditCourseMapping = useCallback(() => {
    fetchArchitectures(true)
  }, [fetchArchitectures])

  const onClosedManageProgram = useCallback(() => {
    fetchPrograms()
  }, [fetchPrograms])

  useEffect(() => {
    Emitter.on(Events.OnClosedEditCourseMapping, onClosedEditCourseMapping)
    Emitter.on(Events.OnClosedManageProgram, onClosedManageProgram)
    Emitter.on(Events.OnClosedEditCourse, onClosedEditCourse)
    return () => {
      Emitter.off(Events.OnClosedEditCourseMapping, onClosedEditCourseMapping)
      Emitter.off(Events.OnClosedManageProgram, onClosedManageProgram)
      Emitter.off(Events.OnClosedEditCourse, onClosedEditCourse)
    }
  }, [onClosedEditCourse, onClosedEditCourseMapping, onClosedManageProgram])

  useEffect(() => {
    fetchArchitectures()
    fetchPrograms()
  }, [fetchArchitectures, fetchPrograms])

  return (
    <>
      <div
        className={`flex flex-col gap-8 w-full overflow-y-auto table-scrollbar table-scrollbar-grey`}
        style={{ height: 'calc(100vh - 300px)' }}>
        {!isLoading && (
          <div className="section-programs">
            <div className="flex flex-col gap-0">
              <div className="flex flex-row gap-8 sticky top-0 z-30 bg-purple-950 pb-8">
                <div className="w-[60%]">
                  <DropDownMenu
                    ref={refDropDownProgram}
                    items={programs}
                    onSelected={onSelectedProgram}
                    style={{ zIndex: 30, fontSize: '14px' }}
                  />
                </div>
                <div className="w-[40%]">
                  <DropDownMenu
                    ref={refDropDownCohort}
                    items={cohorts}
                    onSelected={onSelectedCohort}
                    style={{ zIndex: 30, fontSize: '14px' }}
                  />
                </div>
              </div>

              <div ref={refTableDiv} className="flex flex-col gap-4 text-white max-h-[100%] w-full">
                <table className="mentem-table mentem-table-highlight text-black">
                  <thead>
                    <tr>
                      {tableHeaders.map((header, index: number) => {
                        return (
                          <th
                            className={`${alternateStyleV2('th', index)} h-[55px] ${getColumnWidth(header.key)}`}
                            key={header.key}>
                            <div className="flex flex-row items-center gap-2 px-2">
                              <span className="grow">{header.value}</span>
                              {header.sorting !== false && (
                                <img
                                  className="cursor-pointer"
                                  src={index % 2 === 0 ? sortSvg : sort2Svg}
                                  alt="sort"
                                  onClick={() => onClickSort(header.sortKey)}
                                  data-tooltip-id={`tooltip-${header.key}`}
                                />
                              )}
                            </div>
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {sortedCourses.length > 0 &&
                      sortedCourses.map(item => {
                        return renderRow(item)
                      })}
                  </tbody>
                </table>
                {sortedCourses.length === 0 && (
                  <p className="p-4 text-white font-[600] text-[14px] text-roboto">No data found</p>
                )}
              </div>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="flex items-center justify-center my-8">
            <LoadingIndicator />
          </div>
        )}
      </div>
      {tableHeaders.map(header => {
        return (
          <Tooltip
            id={`tooltip-${header.key}`}
            key={header.sortKey}
            className="mentem-tooltip"
            style={getTooptipStyle(header.key)}
            place="top"
            noArrow={true}>
            {getSortingText(header.key)}
          </Tooltip>
        )
      })}
      <Tooltip
        id="tooltip-edit-course-name"
        className="mentem-tooltip"
        style={{ ...tooltipStyle, padding: '6px' }}
        place="top"
        noArrow={true}>
        Edit course name
      </Tooltip>
      <Tooltip
        id="tooltip-map-course"
        className="mentem-tooltip"
        style={{ ...tooltipStyle, padding: '6px' }}
        place="top"
        noArrow={true}>
        Edit course mapping
      </Tooltip>
      <Tooltip
        id="tooltip-edit-course"
        className="mentem-tooltip"
        style={{ ...tooltipStyle, padding: '6px' }}
        place="top"
        noArrow={true}>
        Edit course details
      </Tooltip>
    </>
  )
}

import React from 'react'
import { useState, useEffect } from 'react'
import ReactFlow, {
  Node,
  Edge,
  Controls,
  Background,
  ConnectionMode,
  Position,
  BackgroundVariant,
  NodeProps
} from 'reactflow'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import 'reactflow/dist/style.css'
import { AnalyserAPI } from '../apis/AnalyserAPI'
import { EmailEngagementReportResponseDTO, TimePattern, DayPattern } from '../apis/AnalyserAPI.dto'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface ChartData {
  hour: string
  sent: number
  engaged: number
}

interface DayChartData {
  day: string
  sent: number
  engaged: number
}

interface CustomNodeData {
  label: string
  timeChartData: ChartData[]
  dayChartData: DayChartData[]
  activeTab: 'time' | 'day'
  setActiveTab: (tab: 'time' | 'day') => void
}

const CustomNode = ({ data }: NodeProps<CustomNodeData>) => {
  const { activeTab, setActiveTab } = data

  const timeChartData = {
    labels: data.timeChartData.map(d => d.hour),
    datasets: [
      {
        label: 'Sent',
        data: data.timeChartData.map(d => d.sent),
        backgroundColor: 'rgba(53, 162, 235, 0.5)'
      },
      {
        label: 'Engaged',
        data: data.timeChartData.map(d => d.engaged),
        backgroundColor: 'rgba(75, 192, 192, 0.5)'
      }
    ]
  }

  const dayChartData = {
    labels: data.dayChartData.map(d => d.day),
    datasets: [
      {
        label: 'Sent',
        data: data.dayChartData.map(d => d.sent),
        backgroundColor: 'rgba(53, 162, 235, 0.5)'
      },
      {
        label: 'Engaged',
        data: data.dayChartData.map(d => d.engaged),
        backgroundColor: 'rgba(75, 192, 192, 0.5)'
      }
    ]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: activeTab === 'time' ? 'Time' : 'Day'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Emails'
        },
        ticks: {
          stepSize: 1
        }
      }
    }
  }

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg">
      <div className="text-2xl font-bold mb-4">{data.label}</div>
      <div className="flex justify-center border-b mb-4">
        <button
          className={`px-4 py-2 ${activeTab === 'time' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
          onClick={() => setActiveTab('time')}>
          Time
        </button>
        <button
          className={`px-4 py-2 ${activeTab === 'day' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
          onClick={() => setActiveTab('day')}>
          Day
        </button>
      </div>
      <div style={{ height: '800px', width: '1200px' }}>
        <Bar options={options} data={activeTab === 'time' ? timeChartData : dayChartData} />
      </div>
    </div>
  )
}

const nodeTypes = { custom: CustomNode }

const BusinessReport: React.FC = () => {
  const [nodes, setNodes] = useState<Node[]>([])
  const [edges, setEdges] = useState<Edge[]>([])
  const [emailReport, setEmailReport] = useState<EmailEngagementReportResponseDTO | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [activeTab, setActiveTab] = useState<'time' | 'day'>('time')

  // Fetch data
  useEffect(() => {
    const fetchEmailReport = async () => {
      try {
        const data = await AnalyserAPI.getEmailReport()
        console.log('Email Report:', data)
        setEmailReport(data)
        setLoading(false)
      } catch (err) {
        console.error('Error fetching email report:', err)
        const errorMessage = err instanceof Error ? err.message : 'An unexpected error occurred'
        setError(errorMessage)
        setLoading(false)
      }
    }

    fetchEmailReport()
  }, [])

  // Initialize flow chart once data is loaded
  useEffect(() => {
    if (emailReport?.owners) {
      console.log('Creating nodes from owners:', emailReport.owners)
      const ownerNodes: Node[] = emailReport.owners.map((owner, index) => {
        const timeChartData: ChartData[] = owner.patterns.time.map((time: TimePattern) => ({
          hour: time.hour.toString(),
          sent: time.sent,
          engaged: time.engaged
        }))

        const dayChartData: DayChartData[] = owner.patterns.day.map((day: DayPattern) => ({
          day: day.day,
          sent: day.sent,
          engaged: day.engaged
        }))

        return {
          id: owner.ownerId.toString(),
          position: {
            x: 500,
            y: 50 + index * 1000
          },
          data: {
            label: owner.name || `Owner ${owner.ownerId}`,
            timeChartData,
            dayChartData,
            activeTab,
            setActiveTab
          },
          type: 'custom',
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          style: {
            padding: 10,
            borderRadius: 5,
            background: 'transparent'
          }
        }
      })
      console.log('Created nodes:', ownerNodes)
      setNodes(ownerNodes)
    } else {
      console.log('No owners found in emailReport:', emailReport)
    }
  }, [emailReport, activeTab, setActiveTab])

  // Add debug log for nodes state
  useEffect(() => {
    console.log('Current nodes:', nodes)
  }, [nodes])

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div className="text-red-500">{error}</div>
  }

  if (!emailReport || nodes.length === 0) {
    return <div>Initializing flow chart...</div>
  }

  return (
    <div className="flex grow flex-col h-screen w-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <div
        className="border-b border-gray-300 shadow-sm"
        style={{
          backgroundColor: 'rgb(251, 220, 3)'
        }}>
        <div className="container mx-auto px-4">
          <div className="text-3xl font-bold text-center py-6 text-gray-800 flex items-center justify-center">
            <svg
              className="w-8 h-8 mr-3"
              fill="none"
              stroke="black"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
              />
            </svg>
            Behaviour Reports
          </div>
        </div>
      </div>
      <div className="flex-1 p-4" style={{ width: '100%', height: 'calc(100vh - 5rem)', backgroundColor: '#f8fafc' }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          connectionMode={ConnectionMode.Loose}
          fitView
          minZoom={0.5}
          maxZoom={2}
          preventScrolling
          nodesDraggable
          nodeTypes={nodeTypes}
          fitViewOptions={{ padding: 0.2 }}>
          <Background variant={BackgroundVariant.Dots} gap={0} color="#ffffff" />
          <Controls className="bg-white shadow-lg" />
        </ReactFlow>
      </div>
    </div>
  )
}

export default BusinessReport

import { useEffect, useMemo, useRef, useState } from 'react'
import { LearnerStatus, SisLearnerWithCourse } from '../apis/entities/sis.entity'
import closeSvg from '../images/close2.svg'
import Button from '../components/Button'
import { SisAPI } from '../apis/SisAPI'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { Box, styled } from '@mui/material'
import PlusIcon from '@mui/icons-material/Add'
import MinusIcon from '@mui/icons-material/Remove'
import Checkbox from '../components/Checkbox'
import { toastInfo } from '../utils/toast'

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none'
        },
        '.expandIconWrapper': {
          display: 'none'
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block'
        }
      }}>
      <div className="expandIconWrapper">
        <MinusIcon />
      </div>
      <div className="collapsIconWrapper">
        <PlusIcon />
      </div>
    </Box>
  )
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    minWidth: '150px',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&::before': {
      display: 'none'
    },
    '& .Mui-expanded.MuiAccordionSummary-root': {
      border: '1px solid rgba(0, 0, 0, 1)'
    }
  })
)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<CustomExpandIcon />} {...props} />
))(({ theme }) => ({
  fontWeight: 700,
  fontSize: '16px',
  fontFamily: 'Roboto',

  minHeight: '25px',
  '& .MuiAccordionSummary-content': {
    margin: '4px 0'
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 1)',
  fontWeight: 600,
  fontSize: '14px',
  fontFamily: 'Roboto',
  textAlign: 'left'
}))

enum ViewMode {
  SELECT_STATUS,
  SELECT_LEARNERS
}

const statuses = [
  {
    label: 'Change status from Confirmed to Enrolled',
    fromValue: LearnerStatus.Confirmed,
    value: LearnerStatus.Enrolled
  },
  {
    label: 'Change status from In progress to Withdrawn',
    fromValue: LearnerStatus.InProgress,
    value: LearnerStatus.Withdrawn
  },
  {
    label: 'Change status from In progress to Deferred',
    fromValue: LearnerStatus.InProgress,
    value: LearnerStatus.Deferred
  },
  {
    label: 'Change status from In progress to Passed',
    fromValue: LearnerStatus.InProgress,
    value: LearnerStatus.Passed
  },
  {
    label: 'Change status from In progress to Failed',
    fromValue: LearnerStatus.InProgress,
    value: LearnerStatus.Failed
  }
]

interface Props {
  learners: SisLearnerWithCourse[]
  preCheckedIds: Array<number>
  onClose: (updated: boolean) => void
}

export default function ModalSisEditStatus({ learners, preCheckedIds, onClose }: Props): JSX.Element {
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.SELECT_STATUS)
  const [isSaving, setIsSaving] = useState(false)
  const refSelectedStatus = useRef<LearnerStatus>()
  const [groupedLearners, setGroupedLearners] = useState<Array<Array<SisLearnerWithCourse>>>([])
  const [checkedIds, setCheckedIds] = useState<Array<number>>(preCheckedIds)
  const [filteredLearners, setFilteredLearners] = useState<SisLearnerWithCourse[]>([])

  const handleSave = async () => {
    try {
      if (checkedIds.length === 0) {
        toastInfo('Please select at least one learner')
        return
      }

      if (!refSelectedStatus.current) {
        toastInfo('Please select a status')
        return
      }

      setIsSaving(true)

      const items = checkedIds
        .map(mapId => {
          const learner = learners.find(learner => learner.courseToLearnerId === mapId)
          if (!learner) {
            return null
          }
          return {
            learnerId: learner.id,
            courseId: learner.courseId
          }
        })
        .filter(Boolean) as { learnerId: string; courseId: string }[]

      // console.log(items)
      const body = {
        status: refSelectedStatus.current,
        items: items,
        manualUpdate: true
      }
      await SisAPI.bulkUpdateLearnersWithCourse(body)
      onClose(true)
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }

  const getFromValue = (status: LearnerStatus) => {
    return statuses.find(s => s.value === status)?.fromValue
  }

  const onSelectStatus = async (status: LearnerStatus) => {
    refSelectedStatus.current = status
    setViewMode(ViewMode.SELECT_LEARNERS)
    // filter learners based on selected status, default it is confirmed
    const filtered = learners.filter(
      learner =>
        (learner.status || LearnerStatus.Confirmed) === getFromValue(status) && learner.courseToLearnerId !== null
    )
    setFilteredLearners(filtered)

    // update checked ids
    const checkedIds = filtered.map(learner => learner.courseToLearnerId)
    setCheckedIds(checkedIds)
  }

  const toggleCheckbox = (id: number, checked: boolean) => {
    if (checked) {
      setCheckedIds([...checkedIds, id])
    } else {
      setCheckedIds(checkedIds.filter(_id => _id !== id))
    }
  }

  const getCheckedCountInGroup = (group: SisLearnerWithCourse[]) => {
    return group.filter(learner => checkedIds.includes(learner.courseToLearnerId)).length
  }

  const modalStyle = useMemo(() => {
    if (viewMode === ViewMode.SELECT_STATUS) {
      return 'w-auto h-auto'
    } else {
      return 'w-3/4 h-3/4'
    }
  }, [viewMode])

  const getMessage = () => {
    if (checkedIds.length === 0) {
      return 'No valid learners selected. Please check the filters and select at least 1 valid learner for status change.'
    }
    return `This will change the status to ${refSelectedStatus.current} for the following ${checkedIds.length} learner${
      checkedIds.length !== 1 ? 's' : ''
    } - are you sure you want to continue?`
  }

  useEffect(() => {
    // group by course id
    const grouped = filteredLearners.reduce((acc, learner) => {
      const key = learner.courseId
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(learner)
      return acc
    }, {} as Record<string, Array<SisLearnerWithCourse>>)

    const sortedKeys = Object.keys(grouped).sort()
    setGroupedLearners(sortedKeys.map(key => grouped[key]))
  }, [checkedIds, filteredLearners])

  return (
    <div className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center">
      <div
        className={`${modalStyle} flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] px-[12px] pt-[12px] pb-[36px]`}>
        <img
          src={closeSvg}
          className="self-end cursor-pointer hover:opacity-70"
          alt="close"
          onClick={() => {
            onClose(false)
          }}
        />
        {viewMode === ViewMode.SELECT_STATUS && (
          <div className="flex flex-col items-center gap-[24px] px-8 w-full">
            {statuses.map(status => (
              <button
                key={status.value}
                className="sis-button-change-status w-full"
                onClick={() => onSelectStatus(status.value)}>
                {status.label}
              </button>
            ))}
          </div>
        )}
        {viewMode === ViewMode.SELECT_LEARNERS && (
          <div className="flex flex-col items-center gap-[24px] px-8 w-full h-full">
            <div className="flex flex-col overflow-auto w-full grow">
              <p className="text-left text-[#FB0505] font-[700] text-[16px]">{getMessage()}</p>
              <div className="my-[12px] w-full">
                {groupedLearners.map((group, index) => (
                  <Accordion key={index} className="w-full" defaultExpanded>
                    <AccordionSummary>
                      <div className="flex flex-row w-full">
                        <p>{`${group[0].programName || '[No program]'} / ${group[0].cohortName || '[No cohort]'} / ${
                          group[0].courseName || '[No course]'
                        }`}</p>
                        <div className="grow" />
                        <span className="text-primary pr-[20px]">({getCheckedCountInGroup(group)})</span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-2">
                        {group.map((learner, index) => (
                          <div key={index} className="flex flex-row gap-1">
                            <Checkbox
                              id={`${learner.courseToLearnerId}`}
                              checked={checkedIds.includes(learner.courseToLearnerId)}
                              onChange={checked => {
                                toggleCheckbox(learner.courseToLearnerId, checked)
                              }}
                            />
                            <div className="flex items-center relative group max-w-[100px] sm:max-w-[30px] md:max-w-[40px] lg:max-w-[70px] xl:max-w-[90px] 2xl:max-w-[100px]">
                              <span
                                className="truncate block cursor-pointer"
                                onClick={() => {
                                  // toggle the checkbox
                                  const checked = checkedIds.includes(learner.courseToLearnerId)
                                  toggleCheckbox(learner.courseToLearnerId, !checked)
                                }}>
                                {learner.firstName} {learner.lastName}
                              </span>
                              <div className="absolute left-0 bottom-full mb-1 ml-[-6px] hidden w-max max-w-xs px-[6px] py-[3px] text-[14px] text-black bg-white rounded-lg shadow-lg border border-[#6B69C1] group-hover:block">
                                {learner.firstName} {learner.lastName}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
            <div className="self-end">
              <Button
                label="Yes"
                disabled={isSaving || checkedIds.length === 0}
                isLoading={isSaving}
                onClick={handleSave}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

import { documentApiClient as apiClient } from './configs/AxiosClient'
import {
  SisArchitecture,
  SisClient,
  SisCohort,
  SisCourse,
  SisLearnerWithCourse,
  SisProgram
} from './entities/sis.entity'

export const SisAPI = {
  // only return programs, cohorts, courses
  getArchitectures: async (clientId: string): Promise<SisArchitecture> => {
    const response: any = await apiClient.get(`/sis/architectures/?clientId=${clientId}`)
    return response.data
  },
  getClients: async (): Promise<SisClient[]> => {
    const response: any = await apiClient.get(`/sis/architectures/clients`)
    return response.data
  },
  getClient: async (clientId: string): Promise<SisClient> => {
    const response: any = await apiClient.get(`/sis/architectures/clients/${clientId}`)
    return response.data
  },
  updateClient: async (clientId: string, body: any): Promise<SisClient> => {
    const response: any = await apiClient.patch(`/sis/architectures/clients/${clientId}`, body)
    return response.data
  },
  getLearnersByClient: async (clientId: string): Promise<{ learners: SisLearnerWithCourse[]; count: number }> => {
    const response: any = await apiClient.get(`/sis/architectures/clients/${clientId}/learners`)
    return response.data
  },
  getPrograms: async (clientId: string): Promise<SisProgram[]> => {
    const response: any = await apiClient.get(`/sis/architectures/programs?clientId=${clientId}`)
    return response.data
  },
  getProgram: async (programId: string): Promise<SisProgram> => {
    const response: any = await apiClient.get(`/sis/architectures/programs/${programId}`)
    return response.data
  },
  addProgram: async (name: string, clientId: string): Promise<SisProgram> => {
    const dto = {
      name,
      clientId
    }
    const response: any = await apiClient.post(`/sis/architectures/programs`, dto)
    return response.data
  },
  updateProgram: async (programId: string, name?: string, positions?: string[]): Promise<SisProgram> => {
    const dto = {
      name,
      positions
    }
    const response: any = await apiClient.patch(`/sis/architectures/programs/${programId}`, dto)
    return response.data
  },
  deleteProgram: async (programId: string): Promise<void> => {
    await apiClient.delete(`/sis/architectures/programs/${programId}`)
  },
  addCohort: async (name: string, programId: string): Promise<SisCohort> => {
    const dto = {
      name,
      programId
    }
    const response: any = await apiClient.post(`/sis/architectures/cohorts`, dto)
    return response.data
  },
  updateCohort: async (cohortId: string, name: string): Promise<SisCohort> => {
    const dto = {
      name
    }
    const response: any = await apiClient.patch(`/sis/architectures/cohorts/${cohortId}`, dto)
    return response.data
  },
  deleteCohort: async (cohortId: string): Promise<void> => {
    await apiClient.delete(`/sis/architectures/cohorts/${cohortId}`)
  },
  getCourses: async (clientId: string): Promise<SisCourse[]> => {
    const response: any = await apiClient.get(`/sis/architectures/courses?clientId=${clientId}`)
    return response.data
  },
  getCourse: async (courseId: string): Promise<SisCourse> => {
    const response: any = await apiClient.get(`/sis/architectures/courses/${courseId}`)
    return response.data
  },
  addCourse: async (name: string, cohortId: string): Promise<SisCourse> => {
    const dto = {
      name,
      cohortId
    }
    const response: any = await apiClient.post(`/sis/architectures/courses`, dto)
    return response.data
  },
  mapCoursesToCohort: async (cohortId: string, courseIds: string[]): Promise<SisCourse> => {
    const dto = {
      cohortId,
      courseIds
    }
    const response: any = await apiClient.post(`/sis/architectures/map-courses-to-cohort`, dto)
    return response.data
  },
  mapLearnersToCourse: async (courseId: string, learnerIds: string[]): Promise<SisCourse> => {
    const dto = {
      courseId,
      learnerIds
    }
    const response: any = await apiClient.post(`/sis/architectures/map-learners-to-course`, dto)
    return response.data
  },
  updateCourse: async (courseId: string, dto: any): Promise<SisCourse> => {
    const response: any = await apiClient.patch(`/sis/architectures/courses/${courseId}`, dto)
    return response.data
  },
  deleteCourse: async (courseId: string): Promise<void> => {
    await apiClient.delete(`/sis/architectures/courses/${courseId}`)
  },
  getLearners: async (clientId: string): Promise<{ learners: SisLearnerWithCourse[]; count: number }> => {
    const response: any = await apiClient.get(`/sis/architectures/learners?clientId=${clientId}`)
    return response.data
  },
  addLearner: async (body: any): Promise<SisLearnerWithCourse> => {
    const response: any = await apiClient.post(`/sis/architectures/learners`, body)
    return response.data
  },
  updateLearner: async (learnerId: string, body: any): Promise<SisLearnerWithCourse> => {
    const response: any = await apiClient.patch(`/sis/architectures/learners/${learnerId}`, body)
    return response.data
  },
  updateLearnerWithCourse: async (
    clientId: string,
    courseId: string,
    learnerId: string,
    body: any
  ): Promise<SisLearnerWithCourse> => {
    const response: any = await apiClient.patch(
      `/sis/architectures/clients/${clientId}/courses/${courseId}/learners/${learnerId}`,
      body
    )
    return response.data
  },
  syncInsightsClients: async (): Promise<{ success: boolean }> => {
    const response: any = await apiClient.post(`/sis/architectures/sync-insights-clients`)
    return response.data
  },
  syncEoiLearners: async (clientId: string): Promise<{ success: boolean }> => {
    const response: any = await apiClient.post(`/sis/architectures/sync-eoi-learners`, {
      clientId
    })
    return response.data
  },
  syncInsightsLearners: async (clientId: string): Promise<{ success: boolean }> => {
    const response: any = await apiClient.post(`/sis/architectures/sync-insights-learners`, {
      clientId
    })
    return response.data
  },
  syncCanvas: async (clientId: string): Promise<{ success: boolean }> => {
    const response: any = await apiClient.post(`/sis/architectures/sync-canvas`, {
      clientId
    })
    return response.data
  },
  syncInsightsCohorts: async (clientId: string): Promise<{ success: boolean }> => {
    const response: any = await apiClient.post(`/sis/architectures/sync-insights-cohorts`, {
      clientId
    })
    return response.data
  },
  exportLearners: async (clientId: string): Promise<any> => {
    const response: any = await apiClient.get(`/sis/architectures/clients/${clientId}/learners/export`, {
      responseType: 'blob'
    })
    return response.data
  },
  bulkUpdateLearnersWithCourse: async (body: {
    items: {
      learnerId: string
      courseId: string
    }[]
    status: string
    manualUpdate: boolean
  }): Promise<{ success: boolean }> => {
    const response: any = await apiClient.post(`/sis/architectures/learners/bulk-update`, body)
    return response.data
  },
  getMyClient: async (): Promise<{ clientId: string }> => {
    const response: any = await apiClient.get(`/sis/architectures/clients/me`)
    return response.data
  }
}

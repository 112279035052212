import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import SidebarLayout from '../components/SidebarLayout'
import { ClientDetails } from '../apis/entities/clientDetails.entity'
import { AdminAPI } from '../apis/AdminAPI'
import LoadingIndicator from '../components/LoadingIndicator'
import { isLocal, isProduction } from '../utils/EnvUtils'
import ClientManageUsersView from '../views/ClientManageUsersView'
import ClientManageCoursesView from '../views/ClientManageCoursesView'
import { useAuth } from '../context/AuthProvider'
import Emitter, { Events } from '../core/emitter'
import { SisArchitectureCourse, SisProgram } from '../apis/entities/sis.entity'
import ModalSisCourseMapping from '../modals/ModalSisCourseMapping'
import ModalSisManagePrograms from '../modals/ModalSisManagePrograms'
import ModalSisEditCourse from '../modals/ModalSisEditCourse'

export default function Client() {
  enum ManageView {
    Users,
    Courses
  }

  const { isLogged } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const lastPath = location.pathname.split('/').pop()
  const orgId = lastPath || ''

  const [view, setView] = useState<ManageView>(isLocal ? ManageView.Courses : ManageView.Users) // debug in local
  const [clientDetails, setClientDetails] = useState<ClientDetails | undefined>()
  // for modals
  const [showManageProgram, setShowManageProgram] = useState(false)
  const [showEditCourseMapping, setShowEditCourseMapping] = useState(false)
  const [showEditCourse, setShowEditCourse] = useState(false)
  const [programs, setPrograms] = useState<SisProgram[]>([])
  const [selectedCourse, setSelectedCourse] = useState<SisArchitectureCourse | null>(null)

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      // organization in app_db
      const data = await AdminAPI.getClient(orgId)
      setClientDetails(data)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }, [orgId])

  const onManagePrograms = () => {
    setShowManageProgram(true)
  }

  const onShowEditCourseMapping = (data: { course: SisArchitectureCourse; programs: SisProgram[] }) => {
    setSelectedCourse(data.course)
    setPrograms(data.programs)
    setShowEditCourseMapping(true)
  }

  const OnShowEditCourseInformation = (data: { course: SisArchitectureCourse }) => {
    setSelectedCourse(data.course)
    setShowEditCourse(true)
  }

  useEffect(() => {
    Emitter.on(Events.OnShowEditCourseMapping, onShowEditCourseMapping)
    Emitter.on(Events.OnShowEditCourse, OnShowEditCourseInformation)
    return () => {
      Emitter.off(Events.OnShowEditCourseMapping, onShowEditCourseMapping)
      Emitter.off(Events.OnShowEditCourse, OnShowEditCourseInformation)
    }
  }, [])

  useEffect(() => {
    if (isLogged) {
      fetchData()
    }
  }, [fetchData, isLogged])

  return (
    <>
      <SidebarLayout>
        <div className="flex grow flex-col gap-8 p-16 pt-0 w-full">
          {!isLoading && (
            <>
              <div className="flex flex-col items-start gap-8 w-full sticky top-0 z-[999] bg-purple-950 pt-16">
                <div className="flex flex-row w-full">
                  <div className="page-title-v2">Clients/{clientDetails?.name}</div>
                  {!isProduction && view === ManageView.Courses && (
                    <>
                      <div className="grow" />
                      <button className="button-primary" onClick={onManagePrograms}>
                        Manage programs & cohorts
                      </button>
                    </>
                  )}
                </div>
                {!isProduction && clientDetails && (
                  <div className="flex flex-row w-full">
                    <button
                      className={`flex-1 text-[14px] font-bold ${
                        view === ManageView.Users
                          ? 'text-white bg-[#BD69FE] border border-[#B5B5BE]'
                          : 'text-black border-t border-b border-r border-[#B5B5BE] bg-white shadow-[0px_120px_34px_0px_rgba(0,0,0,0.00),0px_77px_31px_0px_rgba(0,0,0,0.01),0px_43px_26px_0px_rgba(0,0,0,0.05),0px_19px_19px_0px_rgba(0,0,0,0.09),0px_5px_11px_0px_rgba(0,0,0,0.10)]'
                      }`}
                      onClick={() => setView(ManageView.Users)}>
                      <div className="flex flex-col items-center gap-[3px] py-[10px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.49935 1.6665C5.19816 1.6665 3.33268 3.53198 3.33268 5.83317C3.33268 8.13436 5.19816 9.99984 7.49935 9.99984C9.80054 9.99984 11.666 8.13436 11.666 5.83317C11.666 3.53198 9.80054 1.6665 7.49935 1.6665ZM7.49935 3.33317C8.88006 3.33317 9.99935 4.45246 9.99935 5.83317C9.99935 7.21388 8.88006 8.33317 7.49935 8.33317C6.11864 8.33317 4.99935 7.21388 4.99935 5.83317C4.99935 4.45246 6.11864 3.33317 7.49935 3.33317ZM13.3288 15.6524C13.2342 13.4351 11.4066 11.6665 9.16602 11.6665H5.83268L5.65194 11.6704C3.4346 11.765 1.66602 13.5925 1.66602 15.8332V17.4998L1.67162 17.597C1.71976 18.0115 2.07199 18.3332 2.49935 18.3332C2.95959 18.3332 3.33268 17.9601 3.33268 17.4998V15.8332L3.33693 15.6863C3.41296 14.3739 4.50128 13.3332 5.83268 13.3332H9.16602L9.31291 13.3374C10.6252 13.4135 11.666 14.5018 11.666 15.8332V17.4998L11.6716 17.597C11.7198 18.0115 12.072 18.3332 12.4993 18.3332C12.9596 18.3332 13.3327 17.9601 13.3327 17.4998V15.8332L13.3288 15.6524ZM12.5254 2.40147C12.6395 1.95562 13.0935 1.68672 13.5394 1.80088C15.383 2.27293 16.6726 3.9342 16.6726 5.83734C16.6726 7.74047 15.383 9.40174 13.5394 9.8738C13.0935 9.98795 12.6395 9.71906 12.5254 9.2732C12.4112 8.82735 12.6801 8.37337 13.126 8.25921C14.2322 7.97598 15.0059 6.97922 15.0059 5.83734C15.0059 4.69546 14.2322 3.69869 13.126 3.41546C12.6801 3.30131 12.4112 2.84733 12.5254 2.40147ZM15.2077 11.818C14.762 11.7029 14.3075 11.9709 14.1925 12.4165C14.0774 12.8621 14.3454 13.3167 14.791 13.4317C15.8897 13.7154 16.6595 14.7033 16.666 15.838L16.666 17.4998L16.6716 17.597C16.7198 18.0115 17.072 18.3332 17.4993 18.3332C17.9596 18.3332 18.3327 17.9601 18.3327 17.4998V15.8332L18.3274 15.6444C18.2356 13.8288 16.9777 12.2749 15.2077 11.818Z"
                            fill={view === ManageView.Users ? '#FFF' : '#000'}
                          />
                        </svg>
                        <div>Invite users</div>
                      </div>
                    </button>
                    <button
                      className={`flex-1 text-[14px] font-bold ${
                        view === ManageView.Courses
                          ? 'text-white bg-[#BD69FE] border border-[#B5B5BE]'
                          : 'text-black border-t border-b border-l border-[#B5B5BE] bg-white shadow-[0px_120px_34px_0px_rgba(0,0,0,0.00),0px_77px_31px_0px_rgba(0,0,0,0.01),0px_43px_26px_0px_rgba(0,0,0,0.05),0px_19px_19px_0px_rgba(0,0,0,0.09),0px_5px_11px_0px_rgba(0,0,0,0.10)]'
                      }`}
                      onClick={() => setView(ManageView.Courses)}>
                      <div className="flex flex-col items-center gap-[3px] py-[10px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.5012 9.45814C17.4817 9.14207 17.2847 8.86438 16.9929 8.74148L15.2012 7.97481L17.0845 6.89148C17.3568 6.73487 17.5179 6.43847 17.5012 6.12481C17.4817 5.80874 17.2847 5.53104 16.9929 5.40814L10.3262 2.56648V2.56648C10.1184 2.47849 9.88392 2.47849 9.67618 2.56648L3.00952 5.40814C2.71767 5.53104 2.5207 5.80874 2.50118 6.12481H2.50118C2.48449 6.43847 2.64557 6.73487 2.91785 6.89148L4.80118 7.97481L3.00952 8.74148H3.00952C2.71767 8.86438 2.5207 9.14207 2.50118 9.45814L2.50118 9.45814C2.48449 9.7718 2.64557 10.0682 2.91785 10.2248L4.80118 11.3081L3.00952 12.0748C2.71767 12.1977 2.5207 12.4754 2.50118 12.7915H2.50118C2.48449 13.1051 2.64557 13.4015 2.91785 13.5581L9.58452 17.3915C9.84235 17.5403 10.16 17.5403 10.4179 17.3915L17.0845 13.5581C17.3568 13.4015 17.5179 13.1051 17.5012 12.7915C17.4817 12.4754 17.2847 12.1977 16.9929 12.0748L15.2012 11.3081L17.0845 10.2248C17.3568 10.0682 17.5179 9.7718 17.5012 9.45814V9.45814ZM10.0012 4.24148L14.8012 6.28314L10.0012 9.04148L5.20118 6.28314L10.0012 4.24148ZM9.58452 10.7248C9.84235 10.8737 10.16 10.8737 10.4179 10.7248L13.3928 9.05814L14.8012 9.65814L10.0012 12.3748L5.20118 9.61648L6.60952 9.01648L9.58452 10.7248ZM14.8012 12.9498L10.0012 15.7081L5.20118 12.9498L6.60952 12.3498L9.58452 14.0581C9.84235 14.207 10.16 14.207 10.4178 14.0581L13.3928 12.3498L14.8012 12.9498Z"
                            fill={view === ManageView.Courses ? '#FFF' : '#000'}
                          />
                        </svg>
                        <div>Manage courses</div>
                      </div>
                    </button>
                  </div>
                )}
              </div>
              {!isProduction && view === ManageView.Users && clientDetails && (
                <ClientManageUsersView clientDetails={clientDetails} />
              )}
              {!isProduction && view === ManageView.Courses && <ClientManageCoursesView />}
              {isProduction && clientDetails && <ClientManageUsersView clientDetails={clientDetails} />}
            </>
          )}
          {isLoading && (
            <div className="flex items-center justify-center w-full h-full">
              <LoadingIndicator />
            </div>
          )}
        </div>
      </SidebarLayout>
      {showEditCourseMapping && selectedCourse && programs && (
        <ModalSisCourseMapping
          programs={programs}
          course={selectedCourse}
          onClose={updated => {
            if (updated) {
              // emit event to update course
              Emitter.emit(Events.OnClosedEditCourseMapping, null)
            }
            setShowEditCourseMapping(false)
          }}
        />
      )}
      {showManageProgram && clientDetails && (
        <ModalSisManagePrograms
          clientId={clientDetails.id}
          onClose={() => {
            // once closed, emit event to update client details
            Emitter.emit(Events.OnClosedManageProgram, null)
            setShowManageProgram(false)
          }}
        />
      )}
      {showEditCourse && selectedCourse && (
        <ModalSisEditCourse
          course={selectedCourse}
          onClose={(updated, updatedCourse) => {
            if (updated) {
              // emit event to update course
              Emitter.emit(Events.OnClosedEditCourse, updatedCourse)
              // keep the modal open
            } else {
              setShowEditCourse(false)
            }
          }}
        />
      )}
    </>
  )
}

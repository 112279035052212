import { Routes, Route } from 'react-router-dom'
import './App.css'
import Clients from './pages/Clients'
import Debug from './pages/Debug'
import Admins from './pages/Admins'
import Client from './pages/Client'
import { LoadingProvider } from './context/LoadingProvider'
import { AxiosClient } from './apis/configs/AxiosClient'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { DataProvider } from './context/DataProvider'
import LXE from './pages/LXE'
import Assessments from './pages/Assessments'
import AssessmentReport from './pages/AssessmentReport'
import AssessmentIndividualReport from './pages/AssessmentIndividualReport'
import ProtectedRoute from './routes/ProtectedRoute'
import AuthProvider, { Role } from './context/AuthProvider'
import Home from './pages/Home'
import Path from './routes/Path'
import EOI from './pages/EOI'
import EoiProgramReport from './pages/EoiProgramReport'
import EoiClient from './pages/EoiClient'
import DynamicContext from './pages/DynamicContext'
import Learners from './pages/Learners'
import LearnersDetails from './pages/LearnersDetails'
import LearnersDetailsForClient from './pages/LearnersDetailsForClient'
import BusinessReport from './pages/BusinessReport'

function App() {
  return (
    <AxiosClient>
      <AuthProvider>
        <DataProvider>
          <LoadingProvider>
            <div className="App">
              <ToastContainer />
              <Routes>
                <Route path={Path.root.path} element={<ProtectedRoute component={Home} roles={[Role.All]} />} />
                <Route path={Path.clients.path} element={<ProtectedRoute component={Clients} />} />
                <Route path={Path.debug.path} element={<ProtectedRoute component={Debug} />} />
                <Route path={Path.administrators.path} element={<ProtectedRoute component={Admins} />} />
                <Route path={Path.lxe.path} element={<ProtectedRoute component={LXE} />} />
                <Route path={Path.client.path} element={<ProtectedRoute component={Client} />} />

                <Route
                  path={Path.assessments.path}
                  element={<ProtectedRoute component={Assessments} roles={[Role.AdminHubUser]} />}
                />

                <Route
                  path={Path.assessmentReport.path}
                  element={<ProtectedRoute component={AssessmentReport} roles={[Role.AdminHubUser]} />}
                />

                <Route
                  path={Path.assessmentIndividual.path}
                  element={<ProtectedRoute component={AssessmentIndividualReport} roles={[Role.AdminHubUser]} />}
                />

                <Route path={Path.eoi.path} element={<ProtectedRoute component={EOI} roles={[Role.AdminHubUser]} />} />

                <Route
                  path={Path.eoiProgramReport.path}
                  element={
                    <ProtectedRoute component={EoiProgramReport} forIframe={false} roles={[Role.AdminHubUser]} />
                  }
                />

                <Route
                  path={Path.eoiForClient.path}
                  element={
                    <ProtectedRoute
                      component={EoiClient}
                      roles={[Role.AdminHubUser, Role.Admin, Role.OrgAdmin, Role.SuperAdmin]}
                    />
                  }
                />
                <Route
                  path={Path.eoiProgramReportForClient.path}
                  element={
                    <ProtectedRoute
                      component={EoiProgramReport}
                      forIframe={true}
                      roles={[Role.AdminHubUser, Role.Admin, Role.OrgAdmin, Role.SuperAdmin]}
                    />
                  }
                />

                <Route
                  path={`eoi/context/:clientCode/:programCode`}
                  element={<ProtectedRoute component={DynamicContext} roles={[Role.SuperAdmin]} />}
                />

                <Route
                  path={Path.learners.path}
                  element={<ProtectedRoute component={Learners} roles={[Role.SuperAdmin]} />}
                />

                <Route
                  path={Path.learnersDetails.path}
                  element={<ProtectedRoute component={LearnersDetails} forIframe={false} roles={[Role.AdminHubUser]} />}
                />

                <Route
                  path={Path.learnersDetailsForClient.path}
                  element={
                    <ProtectedRoute
                      component={LearnersDetailsForClient}
                      roles={[Role.AdminHubUser, Role.Admin, Role.OrgAdmin, Role.SuperAdmin]}
                    />
                  }
                />
                <Route
                  path="/business-report"
                  element={<ProtectedRoute component={BusinessReport} roles={[Role.SuperAdmin]} />}
                />
              </Routes>
            </div>
          </LoadingProvider>
        </DataProvider>
      </AuthProvider>
    </AxiosClient>
  )
}

export default App

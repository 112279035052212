import { useRef, useState } from 'react'
import { SisArchitectureCourse } from '../apis/entities/sis.entity'
import closeSvg from '../images/close2.svg'
import Button from '../components/Button'
import { SisAPI } from '../apis/SisAPI'
import { toastError, toastInfo, toastSuccess } from '../utils/toast'
import Checkbox, { CheckboxSize, CheckboxTheme } from '../components/Checkbox'
import React from 'react'
import { Switch } from '../components/Switch'

const nonCompletionStatuses = [
  {
    name: 'Withdrawn',
    value: true
  },
  {
    name: 'Deferred',
    value: false
  },
  {
    name: 'Terminated',
    value: true
  }
]

const defaultLearningHours = 40

const defaultCompletionConfig = `{
  "statuses": {
  ${nonCompletionStatuses.map(status => `"${status.name}": ${status.value}`).join(',\n')}
  }
}`

const defaultPassCriteria = `{
  "rules": [
    {
      "operator": "AND", 
      "criteria": [
        {
          "id": "pass_score_in_all_tests",
          "description": "Score at least {{value}}% in all tests",
          "checked": true,
          "valueOperator": "gte",
          "value": 70
        },
        {
          "id": "pass_all_marked_assignments", 
          "description": "Pass all marked assignments", 
          "checked": true
        },
        {
          "id": "complete_all_required_activities", 
          "description": "Complete all required activities", 
          "checked": true
        }
      ]
    }
  ]
}`

function isEmpty(obj: any) {
  return obj === null || obj === undefined || (typeof obj === 'object' && Object.keys(obj).length === 0)
}

interface Props {
  course: SisArchitectureCourse
  onClose: (updated: boolean, updatedCourse?: SisArchitectureCourse) => void
}

export default function ModalSisEditCourse({ course, onClose }: Props): JSX.Element {
  const [isSaving, setIsSaving] = useState(false)
  const refCourse = useRef<SisArchitectureCourse>(course)
  // console.log('course', course)
  const [completionConfig, setCompletionConfig] = useState(
    !isEmpty(course.completionConfig) ? course.completionConfig : JSON.parse(defaultCompletionConfig)
  )
  const [passCriteria, setPassCriteria] = useState(
    !isEmpty(course.passCriteria) ? course.passCriteria : JSON.parse(defaultPassCriteria)
  )
  const refPassCriteria = useRef<HTMLTextAreaElement>(null)
  const [showPassCriteriaJson, setShowPassCriteriaJson] = useState(false)

  const refLearningHours = useRef<HTMLInputElement>(null)

  const [renderKey, setRenderKey] = useState(0) // key changes force re-render for defaultValue / defaultChecked

  // update passCriteria from the textarea changes
  const onChangePassCriteriaJson = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    try {
      const json = JSON.parse(e.target.value)
      setPassCriteria(json)
      setRenderKey(k => k + 1) // increment key to re-render with new default values
    } catch (error) {
      // silently fail or handle error as needed
      console.error('Invalid JSON', error)
    }
  }

  const handleSave = async () => {
    const { startDate, endDate, censusDate } = refCourse.current
    if (!startDate) {
      toastInfo('Please enter a start date')
      return
    }
    if (!endDate) {
      toastInfo('Please enter an end date')
      return
    }
    if (!censusDate) {
      toastInfo('Please enter a census date')
      return
    }

    // validate date logic
    // 1. start date should be before end date
    // 2. census date should be before end date and after or equal to start date
    // console.log(startDate)
    // console.log(endDate)
    // console.log(censusDate)
    if (startDate.getTime() >= endDate.getTime()) {
      toastError('Start date must be before end date')
      return
    }

    if (censusDate.getTime() >= endDate.getTime() || censusDate.getTime() < startDate.getTime()) {
      toastError('Census date must be between start date and end date')
      return
    }

    const learningHours = parseInt(refLearningHours.current?.value || defaultLearningHours.toString())
    if (!learningHours) {
      toastInfo('Please enter the learning hours')
      return
    }

    // validate learning hours, should be a number and greater than 0
    if (isNaN(learningHours) || learningHours <= 0) {
      toastError('Learning hours should be a number and greater than 0')
      return
    }

    if (isEmpty(completionConfig)) {
      toastError('Invalid statuses')
      return
    }

    if (isEmpty(passCriteria)) {
      toastError('Invalid  Pass criteria')
      return
    }

    try {
      setIsSaving(true)
      const result = await SisAPI.updateCourse(course.id, {
        startDate,
        endDate,
        censusDate,
        learningHours,
        completionConfig,
        passCriteria
      })
      if (result) {
        const updatedCourse = { ...course, ...refCourse.current }
        onClose(true, updatedCourse)
        toastSuccess('Course updated successfully')
      } else {
        toastError('Failed to update course')
      }
    } catch (error) {
      console.error(error)
      toastError('An error occurred while updating the course')
    } finally {
      setIsSaving(false)
    }
  }

  const onChangeCompletionConfig = (id: string, checked: boolean) => {
    // check the id and checked status, then update the completionConfig
    try {
      const newCompletionConfig = { ...completionConfig }
      newCompletionConfig.statuses[id] = checked
      console.log(newCompletionConfig)
      setCompletionConfig(newCompletionConfig)
    } catch (error) {
      console.error(error)
    }
  }

  const onChangePassCriteria = (targetId: string, targetValue: string, checked: boolean) => {
    // check the id and checked status, then update the passCriteria
    try {
      const tmpArr = targetId.split('-')
      const id = tmpArr[1]
      const index = parseInt(id)
      const newPassCriteria = { ...passCriteria }
      const ruleIndex = 0 // only one rule for now
      const criterionIndex = index
      if (tmpArr.length === 3 && tmpArr[2] === 'value') {
        newPassCriteria.rules[ruleIndex].criteria[criterionIndex].value = parseInt(targetValue) || 0
      } else {
        newPassCriteria.rules[ruleIndex].criteria[criterionIndex].checked = checked
      }
      console.log(newPassCriteria)
      setPassCriteria(newPassCriteria)

      // sync the textarea with the passCriteria
      if (refPassCriteria.current) {
        refPassCriteria.current.value = JSON.stringify(newPassCriteria, null, 4)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center Overlay">
      <div className="max-h-[90vh] w-auto min-w-[50%] flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] px-[12px] pt-[12px] pb-[36px]">
        <img
          src={closeSvg}
          className="self-end cursor-pointer"
          alt="close"
          onClick={() => {
            onClose(false)
          }}
        />
        <div className="flex flex-col items-center gap-[8px] px-8 w-full overflow-auto">
          <p className="text-[16px] text-start font-[700] font-roboto w-full">
            {course.clientName}/{course.programName}/{course.cohortName}/{course.name}
          </p>
          <div className="flex flex-row items-center w-full gap-8">
            <div className="flex flex-row items-center w-full gap-4">
              <label htmlFor="startDate" className="w-1/2 text-start text-[16px] font-[700] font-roboto">
                Start date
              </label>
              <input
                type="date"
                id="startDate"
                className="input-text w-1/2 text-center"
                style={{ fontSize: '16px', fontFamily: 'Roboto', fontWeight: 700 }}
                placeholder="Start date"
                defaultValue={course.startDate ? new Date(course.startDate).toISOString().split('T')[0] : ''}
                onChange={e => {
                  refCourse.current.startDate = new Date(e.target.value)
                }}
              />
            </div>
            <div className="flex flex-row items-center w-full gap-4">
              <label htmlFor="endDate" className="w-1/2 text-start text-[16px] font-[700] font-roboto">
                End date
              </label>
              <input
                type="date"
                id="endDate"
                className="input-text w-1/2 text-center"
                style={{ fontSize: '16px', fontFamily: 'Roboto', fontWeight: 700 }}
                placeholder="End date"
                defaultValue={course.endDate ? new Date(course.endDate).toISOString().split('T')[0] : ''}
                onChange={e => {
                  refCourse.current.endDate = new Date(e.target.value)
                }}
              />
            </div>
          </div>
          <div className="flex flex-row items-center w-full gap-8">
            <div className="flex flex-row items-center w-full gap-4">
              <label htmlFor="censusDate" className="w-1/2 text-start text-[16px] font-[700] font-roboto">
                Census date
              </label>
              <input
                type="date"
                id="censusDate"
                className="input-text w-1/2 text-center"
                style={{ fontSize: '16px', fontFamily: 'Roboto', fontWeight: 700 }}
                placeholder="Census date"
                defaultValue={course.censusDate ? new Date(course.censusDate).toISOString().split('T')[0] : ''}
                onChange={e => {
                  refCourse.current.censusDate = new Date(e.target.value)
                }}
              />
            </div>
            <div className="flex flex-row items-center w-full gap-4">
              <label htmlFor="learningHours" className="w-1/2 text-start text-[16px] font-[700] font-roboto">
                Learning hours
              </label>
              <input
                type="number"
                id="learningHours"
                ref={refLearningHours}
                className="input-text w-1/2 text-center"
                style={{ fontSize: '16px', fontFamily: 'Roboto', fontWeight: 700 }}
                placeholder={defaultLearningHours.toString()}
                defaultValue={course.learningHours || defaultLearningHours}
                onChange={e => {
                  refCourse.current.learningHours = parseInt(e.target.value)
                }}
              />
            </div>
          </div>
          <p className="text-[16px] text-start font-[700] font-roboto w-full">
            Select statuses which count against course completion
          </p>
          <div className="flex flex-row items-center w-full gap-4">
            {nonCompletionStatuses?.map((status, index) => (
              <div key={index} className="flex flex-row items-center gap-1 cursor-pointer">
                <Checkbox
                  id={`id-${status}`}
                  checked={completionConfig?.statuses?.[status.name]}
                  onChange={checked => {
                    onChangeCompletionConfig(status.name, checked)
                  }}
                  size={CheckboxSize.Medium}
                  theme={CheckboxTheme.Normal}
                  label={status.name}
                  style={{ label: 'text-[14px] font-inter pl-1' }}
                />
              </div>
            ))}
          </div>
          <div className="flex flex-row items-center gap-4 w-full">
            <span className="text-start text-[16px] font-[700] font-roboto">Pass criteria</span>
            <Switch
              checked={showPassCriteriaJson}
              onChange={() => {
                setShowPassCriteriaJson(!showPassCriteriaJson)
              }}
              checkedText="Show Json"
              unCheckedText="Hide Json"
            />
          </div>
          <div className="flex flex-row gap-[12px] w-full">
            {passCriteria?.rules?.[0]?.criteria?.map((criterion: any, index: number) => (
              <div key={index} className="flex flex-row items-center gap-[4px] cursor-pointer">
                <Checkbox
                  id={`criterion-${index}`}
                  checked={criterion.checked}
                  onChange={checked => {
                    onChangePassCriteria(`criterion-${index}`, '', checked)
                  }}
                  size={CheckboxSize.Medium}
                  theme={CheckboxTheme.Normal}
                  label={criterion.description.split('{{value}}')[0]}
                  style={{ label: 'text-[14px] font-inter pl-1' }}
                />

                {criterion.value !== undefined && (
                  <input
                    key={`crit-val-${index}-${renderKey}`}
                    type="number"
                    id={`criterion-${index}-value`}
                    className="input-text text-center"
                    style={{ width: '70px', height: '40px', fontSize: '16px', fontFamily: 'Roboto', fontWeight: 700 }}
                    placeholder="0"
                    defaultValue={criterion.value}
                    onChange={e => {
                      onChangePassCriteria(`criterion-${index}-value`, e.target.value, false)
                    }}
                  />
                )}
                <span
                  className="text-[14px] cursor-pointer flex items-center gap-1"
                  onClick={() => {
                    onChangePassCriteria(`criterion-${index}`, '', !criterion.checked)
                  }}>
                  {criterion.description
                    .split('{{value}}')[1]
                    ?.split('%')
                    .map((part: any, i: any, arr: any) => (
                      <React.Fragment key={i}>
                        {part}
                        {i < arr.length - 1 && (
                          <span className="text-[24px] font-bold leading-none flex items-center">%</span>
                        )}
                      </React.Fragment>
                    ))}
                </span>
              </div>
            ))}
          </div>
          <textarea
            ref={refPassCriteria}
            defaultValue={JSON.stringify(passCriteria, null, 4) || ''}
            placeholder={defaultPassCriteria}
            onChange={onChangePassCriteriaJson}
            className="input-text w-full"
            style={{
              height: '220px',
              border: '1px solid #D5D5DB',
              borderRadius: '3px',
              padding: '16px 12px',
              color: '#000',
              fontSize: '14px',
              fontFamily: 'Roboto',
              fontWeight: 600,
              lineHeight: '20px',
              fontStyle: 'normal',
              outline: 'none',
              display: showPassCriteriaJson ? 'block' : 'none'
            }}
          />
          <div className="self-end mt-[12px]">
            <Button label="Save" disabled={isSaving} isLoading={isSaving} onClick={handleSave} />
          </div>
        </div>
      </div>
    </div>
  )
}

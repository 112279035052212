import { EmailEngagementReportResponseDTO } from './AnalyserAPI.dto'
import { analyserApiClient } from './configs/AxiosClient'
import { AxiosError } from 'axios'

export interface EmailReport {
  // TODO: Add type definitions based on the actual response structure
  [key: string]: any
}

export const AnalyserAPI = {
  getEmailReport: async (): Promise<EmailEngagementReportResponseDTO> => {
    try {
      console.log('Calling API:', `${process.env.REACT_APP_ANALYSER_API_URL}/hubspot/engagements/emails/report`)
      const response = await analyserApiClient.get('/hubspot/engagements/emails/report')
      return response.data
    } catch (error) {
      console.error('API Error:', error)
      throw new Error('Failed to fetch email report')
    }
  }
}
